<template>
  <v-card elevation="2" :color="cardColor" @click="goToDetail">
    <v-card-text class="pr-2 pb-2">
      <v-container>
        <v-row align="center" justify="center">
          <v-col cols="11">
            <v-row>
              <v-col cols="12" class="py-0 px-0">
                <v-row>
                  <v-col cols="auto" class="mr-auto">
                    <v-chip label small :class="labelClasses">
                      {{ notification.category.name }}
                    </v-chip>

                    <span
                      class="
                        px-2
                        font-weight-medium
                        item-notification-unread--text
                        text-heading-2
                      "
                      v-if="!readStatus"
                    >
                      {{ $t("unread") }}
                    </span>
                  </v-col>

                  <v-col cols="4" class="text-right">
                    <span
                      class="
                        font-weight-regular
                        text-heading-1
                        main--text
                        lighten-4
                      "
                    >
                      {{ notification.post_date }}
                    </span>
                  </v-col>
                </v-row>
              </v-col>

              <v-col cols="12" class="px-0">
                <h3
                  class="
                    font-weight-bold
                    main--text
                    item-notification-content-truncate
                    text-justify
                  "
                  v-html="notification.title"
                ></h3>
              </v-col>
            </v-row>
          </v-col>

          <v-col cols="1" class="px-0">
            <v-btn depressed icon :ripple="false">
              <v-icon size="12">$rightArrow</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-container>
    </v-card-text>
  </v-card>
</template>

<script>
export default {
  name: "ListItemNotification",
  props: {
    notification: {
      type: Object,
      default: () => {},
      required: true
    },
    readStatus: {
      type: Boolean,
      default: false,
      required: false
    },
    isDisplayedOnHomePage: {
      type: Boolean,
      default: false,
      required: false
    }
  },
  computed: {
    labelClasses: function() {
      if (!this.notification.type) {
        return "";
      }
      return this.notification.type.id.toLowerCase() === "important"
        ? "white--text item-notification-label-text item-notification-label-important text-uppercase"
        : "grey--text text--darken-2 item-notification-label-text font-weight-medium item-notification-label-regular text-uppercase";
    },
    cardColor: function() {
      return !this.readStatus && this.isDisplayedOnHomePage
        ? "item-notification-unread"
        : "";
    }
  },
  methods: {
    goToDetail() {
      return this.$router.push({
        name: "notification_detail",
        params: { id: this.notification.id }
      });
    }
  }
};
</script>

<style scoped>
.item-notification-label-text.v-chip.v-size--small {
  font-size: 14px;
}

.item-notification-label-important {
  background: #c63d34 !important;
  border-color: #c63d34 !important;
}

.item-notification-label-regular {
  background: transparent !important;
  border-radius: 0 !important;
  border: 0;
  border-bottom: #c2c2c2 solid 1px;
  padding-left: 0;
  padding-right: 0;
}

.item-notification-unread--text {
  position: absolute;
  vertical-align: middle;
  color: #f03636;
}

.item-notification-unread {
  background-color: #ffe1e1 !important;
}

.item-notification-label-date {
  font-size: 12px;
}

.item-notification-content-truncate {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
</style>
