<template>
  <v-col
    class="
      small
      px-0
      mx-0
      rounded
      counts
      py-1
      d-flex
      flex-column
      justify-space-between
      align-center
    "
    :style="{ background: backgroundColor, width: width, height: height }"
  >
    <p class="subtitle">{{ text }}</p>
    <slot></slot>
  </v-col>
</template>
<script>
export default {
  props: {
    text: {
      type: String,
      required: true
    },
    backgroundColor: {
      type: String,
      required: true
    },
    width: {
      type: String,
      required: false,
      default: "71px"
    },
    height: {
      type: String,
      required: false,
      default: "71px"
    }
  }
};
</script>
<style lang="scss" scoped src="./budget.scss"></style>
