<template>
  <v-row
    no-gutters
    class=" my-1 rounded align-center"
    :class="spacingClass"
    :style="{ background: backgroundColor, width: width, height: height }"
  >
    <v-col cols="3"
      ><v-icon :size="iconSize">{{ icon }}</v-icon></v-col
    >
    <slot>
      <v-col cols="4" class="subtitle">{{ name }}</v-col>
      <v-col class="prominent"
        >{{ amount }} <span class="subtitle">日</span></v-col
      >
    </slot>
  </v-row>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      required: false
    },
    amount: {
      type: [String, Number],
      required: false
    },
    backgroundColor: {
      type: String,
      required: true
    },
    icon: {
      type: String,
      required: true
    },
    iconSize: {
      type: String,
      required: false,
      default: "27px"
    },
    spacingClass: {
      type: String,
      required: false,
      default: "pl-3 py-2"
    },
    width: {
      type: String,
      required: false,
      default: "148px"
    },
    height: {
      type: String,
      required: false,
      default: "71px"
    }
  }
};
</script>
<style lang="scss" scoped src="./budget.scss"></style>
<style lang="scss" scoped>
.medium {
  height: 148px;
  width: 71px;
}
</style>
