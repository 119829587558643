<template>
  <v-row v-if="getNotifications.length > 0">
    <v-col cols="12" class="text-center">
      <h4 class="fw-600 font-16px label--text darken-2">
        {{ $t("home_notifications") }}
      </h4>
    </v-col>

    <v-col
      cols="12"
      v-for="(notification, index) in getNotifications"
      :key="`notification-${notification.title}-${index}`"
    >
      <ListItemNotification
        :notification="notification"
        :is-displayed-on-home-page="true"
        :read-status="notification.is_read !== 0"
      />
    </v-col>

    <v-col cols="12" class="text-center mb-12">
      <v-btn
        class="label--text darken-1 font-15px"
        text
        tile
        small
        :ripple="false"
        to="/notifications"
      >
        <v-icon left size="12">$play</v-icon>
        {{ $t("notice_list") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import ListItemNotification from "@/components/layout/notifications/partials/ListItemNotification";
import { mapGetters } from "vuex";
export default {
  name: "HomeListItemNotifications",
  components: { ListItemNotification },
  created() {
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["getNotifications"])
  },
  data() {
    return {};
  },
  methods: {
    getDataFromApi() {
      this.$store.dispatch("NOTIFICATION_GET", {
        params: { paginate: 3, sort: "post_date", order: "desc", npr: 1 }
      });
    }
  }
};
</script>

<style scoped></style>
