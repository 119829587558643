<template>
  <v-row class="mt-3 mb-5" no-gutters>
    <v-col class="mr-1">
      <MediumSummary
        class=" my-1"
        name="営業日数"
        :amount="salesData ? salesData.business_days_count : 0"
        icon="$calendar"
        v-bind="{ backgroundColor }"
        iconSize="30px"
      ></MediumSummary>
      <MediumSummary icon="$sale" v-bind="{ backgroundColor }">
        <v-col>
          <p class="subtitle">累計売上</p>
          <p class="prominent">
            <span class="subtitle1 font-weight-bold">￥</span>
            {{ (salesData ? salesData.total_earnings : "-") | formatNumber }}
          </p>
        </v-col>
      </MediumSummary>
      <MediumSummary icon="$growth" v-bind="{ backgroundColor }">
        <v-col>
          <p class="subtitle">平均売上</p>
          <p class="prominent">
            <span class="subtitle1 font-weight-bold">￥</span
            >{{
              (salesData ? salesData.total_earnings_avg : "-") | formatNumber
            }}
          </p>
        </v-col>
      </MediumSummary>
      <PieChart
        v-bind="{ backgroundColor }"
        :labelColor="pieLabels"
        :pieData="pieData"
        :id="id"
        :key="pieChartRefreshComponent"
      ></PieChart>
    </v-col>
    <v-col :class="{ 'mr-1': reverse }">
      <SaleDistribution
        height="146px"
        v-bind="{ backgroundColor, barColor }"
        :data="distributionData"
      ></SaleDistribution>
      <v-row no-gutters class="mt-0">
        <SmallSummary v-bind="{ backgroundColor }" text="総客数" class="mr-1">
          <div class="d-flex flex-column">
            <div class="">
              <v-icon class="mt-1">$allCustomers</v-icon>
            </div>
            <p class="prominent my-1 d-flex justify-center">
              {{
                (salesData ? salesData.number_of_customers : 0) | formatNumber
              }}
              <span class="fw-700 font-8px customer-count-spacing">人</span>
            </p>
          </div>
        </SmallSummary>
        <SmallSummary text="店内利用客数" v-bind="{ backgroundColor }">
          <div class="d-flex flex-column">
            <div class="d-flex">
              <v-icon class="mt-1">$allCustomersInStore</v-icon>
            </div>
            <p class="prominent my-1 d-flex justify-center">
              {{
                (salesData ? salesData.number_of_customer_store : 0)
                  | formatNumber
              }}
              <span class="fw-700 font-8px customer-count-spacing">人</span>
            </p>
          </div>
        </SmallSummary>
      </v-row>
      <v-row no-gutters>
        <v-col>
          <v-row no-gutters>
            <SmallSummary
              v-bind="{ backgroundColor }"
              class="mt-1 mr-1"
              text="客単価"
            >
              <p class="fw-700 font-16px">
                <span class="fw-700 font-10px mr-n1">￥</span>
                {{
                  (salesData ? salesData.average_sales_per_customer : 0)
                    | formatNumber
                }}
              </p>
              <div class="d-flex justify-center">
                <v-icon size="28" class="mt-1">$customerUnit</v-icon>
              </div>
            </SmallSummary>
            <SmallSummary
              v-bind="{ backgroundColor }"
              class=" my-1"
              text="店内利用客単価"
            >
              <p class="fw-700 font-16px">
                <span class="fw-700 font-10px mr-n1">￥</span>
                {{
                  (salesData
                    ? salesData.average_sales_per_customer_in_store
                    : 0) | formatNumber
                }}
              </p>
              <div class="d-flex justify-center">
                <v-icon class="mt-1" size="32">$customerDinner</v-icon>
              </div>
            </SmallSummary>
          </v-row>
          <v-row no-gutters>
            <SmallSummary
              height="73px"
              v-bind="{ backgroundColor }"
              text="総組数"
            >
              <div class="d-flex justify-center">
                <v-icon class="mt-1" size="26">$pair</v-icon>
              </div>
              <div class="fw-700 font-16px">
                {{
                  (salesData ? salesData.number_of_groups : 0) | formatNumber
                }}
                <span class="font-10px">組</span>
              </div>
            </SmallSummary>
            <SmallSummary
              height="73px"
              v-bind="{ backgroundColor }"
              class="ml-1"
              text="店内利用組数"
            >
              <div class="d-flex justify-center">
                <v-icon class="mt-1" size="30">$group</v-icon>
              </div>

              <div class="fw-700 font-16px mt-n1">
                {{
                  (salesData ? salesData.number_of_group_store : 0)
                    | formatNumber
                }}
                <span class="font-10px">組</span>
              </div>
            </SmallSummary>
          </v-row>
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import PieChart from "@/components/layout/budget/PieChart.vue";
import SaleDistribution from "@/components/layout/budget/SaleDistribution.vue";
import SmallSummary from "@/components/layout/budget/SmallSummary.vue";
import MediumSummary from "@/components/layout/budget/MediumSummary.vue";
export default {
  props: {
    salesData: {
      required: false,
      type: Object
    },
    reverse: {
      required: false,
      type: Boolean,
      default: false
    },
    backgroundColor: {
      required: false,
      type: String,
      default: "#eaf3fc"
    },
    barColor: {
      required: false,
      type: String,
      default: "#6E77F0"
    },
    id: {
      requried: true,
      type: String
    }
  },
  components: {
    PieChart,
    SaleDistribution,
    SmallSummary,
    MediumSummary
  },
  data() {
    return {
      pieLabels: ["#6E77F0", "#A2A8F9", "#DDDEED"],
      // must be sorted
      pieData: this.getPieData(),
      // always should be sorted
      distributionData: this.getMealsPercent(),
      pieChartRefreshComponent: 1
    };
  },
  watch: {
    salesData: {
      deep: true,
      handler(val) {
        if (val) {
          this.distributionData = this.getMealsPercent();
          this.pieData = this.getPieData();
          this.pieChartRefreshComponent++;
        }
      }
    }
  },
  methods: {
    getPieData() {
      return [
        {
          value: this.salesData?.weather?.sunny ?? 0,
          type: "sunny"
        },
        {
          value: this.salesData?.weather?.cloudy ?? 0,
          type: "cloudy"
        },
        {
          value: this.salesData?.weather?.rainy ?? 0,
          type: "rainy"
        }
      ];
    },
    getMealsPercent() {
      return [
        {
          percentage: this.salesData?.percentage?.food_earnings ?? "0",
          icon: "$dining"
        },
        {
          percentage: this.salesData?.percentage?.drinks_earnings ?? "0",
          icon: "$tea"
        },
        {
          percentage: this.salesData?.percentage?.delivery_earnings ?? "0",
          icon: "$delivery"
        },
        {
          percentage: this.salesData?.percentage?.takeout_earnings ?? "0",
          icon: "$takeAwayWithDrink"
        },
        {
          percentage: this.salesData?.percentage?.other_earnings ?? "0",
          icon: "$takeAway"
        }
      ];
    }
  }
};
</script>
<style scoped lang="scss" src="./budget.scss"></style>
