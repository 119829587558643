<template>
  <v-row no-gutters>
    <v-col cols="12">
      <v-row no-gutters>
        <v-col
          class="d-flex align-center font-12px date-color"
          v-if="startDate"
        >
          {{ startDate | formatDate("YYYY-MM-DD (dd)") }}
          <div class="ml-2 d-flex">
            <v-icon
              v-for="icon in icons"
              :key="icon"
              class="mx-1"
              :color="colorSvg(icon)"
            >
              {{ icon }}
            </v-icon>
          </div>
        </v-col>
      </v-row>
      <v-card
        elevation="2"
        class="mt-3"
        :to="getShopAuthUser[0].user_role == 'M' ? buttonLink : ''"
      >
        <v-row no-gutters class="rounded" :class="color">
          <v-col :cols="button ? 10 : 12">
            <BillItem
              v-for="(item, index) in itemList"
              :key="index"
              :name="item.name"
              :price="item.price"
              :description="item.description"
            >
              <div
                v-if="index < itemList.length - 1"
                class="ml-4 dotted desc-width-90"
                :class="dash"
              ></div>
            </BillItem>
          </v-col>
          <v-col
            cols="2"
            v-if="button"
            class="d-flex column align-center justify-center"
          >
            <div v-if="showIcon">
              <v-btn
                width="19"
                min-width="19"
                height="19"
                :to="buttonLink"
                class="d-flex align-center justify-center pa-0"
                :class="button"
                elevation="0"
              >
                <v-icon size="18" color="white"> mdi-chevron-right </v-icon>
              </v-btn>
            </div>
          </v-col>
        </v-row>
      </v-card>
      <v-row class="mt-1" v-if="endDate">
        <v-col class="font-12px date-color">
          {{ endDate | formatDate("YYYY-MM-DD (dd)") }}
        </v-col>
      </v-row>
    </v-col>
  </v-row>
</template>
<script>
import BillItem from "@/components/layout/budget/BillItem";
import { mapGetters } from "vuex";
export default {
  props: {
    itemList: {
      type: Array,
      default: () => {
        return [];
      },
      required: false
    },
    color: {
      type: String,
      required: true
    },
    dash: {
      type: String,
      required: true
    },
    button: {
      type: String,
      required: true,
      default: null
    },
    buttonLink: {
      type: [String, Object],
      required: false,
      default: ""
    },
    startDate: {
      type: String,
      default: null
    },
    endDate: {
      type: String,
      default: null
    },
    icons: {
      type: Array,
      required: false
    },
    showIcon: {
      type: Boolean,
      required: false,
      default: true
    }
  },
  computed: {
    ...mapGetters(["getShopAuthUser"])
  },
  methods: {
    colorSvg(icon) {
      if (icon == "$cloudy") {
        return "#ABABAB";
      } else if (icon === "$umbrella") {
        return "#546FFC";
      } else if (icon === "$sun") {
        return "#E58A00";
      }
      return "#ababab";
    }
  },
  components: { BillItem }
};
</script>
<style lang="scss" scoped>
.yellow-style {
  background: #fffceb;
}
.primary-style {
  background: #f4f8fc;
  ::v-deep .name {
    color: #7d82cf !important;
  }
}
.dotted {
  border: thin dashed;
}
.yellow-dash {
  border: thin dashed #ebeccd;
}
.primary-dash {
  border: thin dashed #bdbdbd;
}
.date-color {
  color: #555555;
}
.yellow-button {
  background-color: #b1a354 !important;
  border-radius: 50% 50% !important;
}
.primary-button {
  background-color: #2e57ab !important;
  border-radius: 50% 50% !important;
}
.desc-width-90 {
  width: 90%;
}
</style>
