<template>
  <div class="chart mt-1 pa-3 rounded" :style="{ background: backgroundColor }">
    <p class="subtitle">天気日数</p>
    <div :id="id"></div>
  </div>
</template>
<script>
import * as d3 from "d3";
export default {
  props: {
    backgroundColor: {
      type: String,
      required: true
    },
    labelColor: {
      type: Array,
      required: true
    },
    pieData: {
      type: Array,
      required: true
    },
    id: {
      type: String,
      required: true
    }
  },
  mounted() {
    this.initPieChart();
  },

  methods: {
    initPieChart() {
      const width = 115;
      const height = 115;
      const margin = 0;
      const radius = Math.min(width, height) / 2 - margin;
      const svg = d3
        .select("#" + this.id)
        .append("svg")
        .attr("width", width)
        .attr("height", height)
        .append("g")
        .attr("transform", `translate(${width / 2}, ${height / 2})`);
      const data = this.pieData.filter(item => {
        return item.value > 0;
      });
      const color = d3.scaleOrdinal().range(this.labelColor);
      const pie = d3.pie().value(function(d) {
        return d[1].value;
      });
      const data_ready = pie(Object.entries(data));
      const arcGenerator = d3
        .arc()
        .innerRadius(0)
        .outerRadius(radius);
      svg
        .selectAll("mySlices")
        .data(data_ready)
        .join("path")
        .attr("d", arcGenerator)
        .attr("fill", function(d) {
          return color(d.data[0]);
        });
      svg
        .selectAll("mySlices")
        .data(data_ready)
        .join("svg:image")
        .attr("xlink:href", d => {
          const temp = d.data[1];
          switch (temp.type) {
            case "rainy":
              return this.umbrellaIcon(temp.value);
            case "sunny":
              return this.sunnyIcon(temp.value);
            case "cloudy":
              return this.cloudyIcon(temp.value);
          }
        })
        .attr("transform", function(d) {
          return `translate(${arcGenerator.centroid(d) + 2})`;
        })
        .attr("width", 30)
        .attr("height", 30)
        .attr("x", -1 * 18)
        .attr("y", -1 * 18);
    },
    umbrellaIcon(val) {
      const icon = `<svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M15.8789 1.74401V0.850462C15.8789 0.380383 15.4858 0 15 0C14.5142 0 14.1211 0.380383 14.1211 0.850462V1.74401C6.29202 2.18801 0 8.47361 0 16.1588C0 16.6289 0.393105 17.0092 0.878905 17.0092C1.3647 17.0092 1.75781 16.6289 1.75781 16.1588C1.75781 15.947 2.69337 15.3083 4.39452 15.3083C6.09567 15.3083 7.08983 15.947 7.08983 16.1588C7.08983 16.6289 7.48293 17.0092 7.96873 17.0092C8.45453 17.0092 8.84764 16.6289 8.84764 16.1588C8.84764 15.947 9.7832 15.3083 11.4844 15.3083C13.1855 15.3083 14.1211 15.947 14.1211 16.1588V25.6273C14.1211 27.5034 15.6978 29.0291 17.6367 29.0291C19.5756 29.0291 21.1523 27.5034 21.1523 25.6273C21.1523 25.1572 20.7592 24.7768 20.2734 24.7768C19.7876 24.7768 19.3945 25.1572 19.3945 25.6273C19.3945 26.5649 18.6057 27.3282 17.6367 27.3282C16.6677 27.3282 15.8789 26.5649 15.8789 25.6273V16.1588C15.8789 15.947 16.8144 15.3083 18.5156 15.3083C20.0533 15.3083 20.9248 15.8167 21.0859 16.0733C21.0855 16.078 21.0858 16.0827 21.0854 16.0873C21.0476 16.5549 21.4089 16.9644 21.893 17.0018C21.9162 17.0034 21.9394 17.0042 21.9626 17.0042C21.9703 17.0042 21.9769 17.0004 21.9847 17.0002C22.001 17.0011 22.0147 17.0092 22.0313 17.0092C22.5171 17.0092 22.9102 16.6289 22.9102 16.1588C22.9102 15.947 23.8457 15.3083 25.5469 15.3083C27.248 15.3083 28.2422 15.947 28.2422 16.1588C28.2422 16.6289 28.6353 17.0092 29.1211 17.0092C29.6069 17.0092 30 16.6289 30 16.1588C29.9999 8.47361 23.7079 2.18801 15.8789 1.74401Z" fill="white"/>
                <text x="13" y="13" fill="#6E77F0" font-size="11px" font-weight="bold" font-faimly="roboto">${val}</text>
              </svg>`;
      const blob = new Blob([icon], { type: "image/svg+xml" });
      const url = URL.createObjectURL(blob);
      return url;
    },

    sunnyIcon(val) {
      const icon = `
                    <svg width="34" height="33" viewBox="0 0 34 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M16.9982 8.20947C12.3019 8.20947 8.48132 11.9071 8.48132 16.4515C8.48132 20.9958 12.3019 24.6935 16.9982 24.6935C21.6938 24.6935 25.5151 20.9965 25.5151 16.4515C25.5151 11.9064 21.6938 8.20947 16.9982 8.20947Z" fill="white"/>
                    <path d="M17.0056 5.78804C16.0877 5.78804 15.3438 5.06818 15.3438 4.18074V1.60803C15.3438 0.719863 16.0877 0 17.0056 0C17.9235 0 18.6674 0.719863 18.6674 1.60803V4.18074C18.6674 5.06818 17.9227 5.78804 17.0056 5.78804Z" fill="white"/>
                    <path d="M17.0056 27.114C16.0877 27.114 15.3438 27.8339 15.3438 28.7221V31.2941C15.3438 32.183 16.0877 32.9028 17.0056 32.9028C17.9235 32.9028 18.6674 32.183 18.6674 31.2941V28.7221C18.6674 27.8339 17.9227 27.114 17.0056 27.114Z" fill="white"/>
                    <path d="M24.793 8.91341C24.1447 8.28536 24.1447 7.26749 24.793 6.63944L26.6732 4.82011C27.3215 4.19278 28.3741 4.19278 29.0232 4.82011C29.6723 5.44816 29.6723 6.46676 29.0232 7.09409L27.143 8.91341C26.4947 9.54147 25.4428 9.54147 24.793 8.91341Z" fill="white"/>
                    <path d="M9.21062 23.9932C8.56156 23.3644 7.50965 23.3644 6.86059 23.9932L4.98041 25.8118C4.3321 26.4392 4.33135 27.4585 4.98041 28.0858C5.62948 28.7131 6.68213 28.7131 7.33044 28.0858L9.21062 26.2657C9.85968 25.6384 9.85968 24.6198 9.21062 23.9932Z" fill="white"/>
                    <path d="M28.0176 16.4507C28.0176 15.5626 28.7615 14.8427 29.6794 14.8427H32.3382C33.256 14.8427 34 15.5626 34 16.4507C34 17.3389 33.256 18.0581 32.3382 18.0581H29.6794C28.7615 18.0581 28.0176 17.3389 28.0176 16.4507Z" fill="white"/>
                    <path d="M5.98163 16.4507C5.98163 15.5626 5.23769 14.8427 4.31981 14.8427H1.66181C0.743938 14.8427 0 15.5626 0 16.4507C0 17.3389 0.743938 18.0581 1.66181 18.0581H4.32056C5.23769 18.0581 5.98163 17.3389 5.98163 16.4507Z" fill="white"/>
                    <path d="M24.7936 23.9949C25.4426 23.3676 26.4953 23.3676 27.1436 23.9949L29.0238 25.8142C29.6728 26.4408 29.6728 27.4602 29.0238 28.0875C28.3747 28.7148 27.3228 28.7148 26.6737 28.0875L24.7936 26.2682C24.1445 25.6401 24.1445 24.6222 24.7936 23.9949Z" fill="white"/>
                    <path d="M9.2109 8.91323C9.85997 8.28517 9.85997 7.26731 9.2109 6.63925L7.33073 4.82065C6.68166 4.19259 5.62976 4.19259 4.98069 4.82065C4.33163 5.44798 4.33163 6.46657 4.98069 7.0939L6.86087 8.91323C7.50993 9.54202 8.56184 9.54202 9.2109 8.91323Z" fill="white"/>
                    <text x="14" y="21" fill="#6E77F0" font-size="11px" font-weight="bold" font-faimly="roboto">${val}</text>
                    </svg>  
                    `;
      const blob = new Blob([icon], { type: "image/svg+xml" });
      const url = URL.createObjectURL(blob);
      return url;
    },

    cloudyIcon(val) {
      const icon = `
                    <svg width="38" height="26" viewBox="0 0 38 26" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M32.8957 11.3168C33.0042 10.7453 33.0589 10.1624 33.0589 9.57544C33.0589 4.29526 28.62 0 23.1632 0C19.6799 0 16.4512 1.79543 14.6774 4.64848C12.283 3.89734 9.82619 4.21943 7.8022 5.56907C5.7782 6.91765 4.57019 9.05283 4.47248 11.3875C1.81172 12.6104 0 15.2141 0 18.0869C0 18.5856 0.124832 19.0719 0.220344 19.5436L0.221443 19.5447C0.93232 23.0159 4.10737 25.5345 7.76994 25.5345H29.7604C34.0038 25.5345 37.5303 22.1931 37.5303 18.0869C37.5303 15.183 35.6939 12.5688 32.8957 11.3168Z" fill="white"/>
                    <text x="16" y="20" fill="#6E77F0" font-size="11px" font-weight="bold" font-faimly="roboto">${val}</text>
                    </svg>
                    `;
      const blob = new Blob([icon], { type: "image/svg+xml" });
      const url = URL.createObjectURL(blob);
      return url;
    }
  }
};
</script>
<style lang="scss" src="./budget.scss" scoped></style>
