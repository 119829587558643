<template>
  <div>
    <v-divider class="item-recommendation-divider"></v-divider>
    <v-card flat class="rounded-0 item-recommendation">
      <v-card-text>
        <v-row>
          <v-col cols="12" class="pb-0">
            <div class="font-weight-regular text-caption">
              {{ date }}
            </div>
          </v-col>
          <v-col cols="12" class="d-inline-flex">
            <h3
              class="
                font-weight-medium
                item-recommendation-content--text
                text-justify
                item-recommendation-content-truncate
              "
              @click="openInNewTab"
            >
              {{ content }} ({{ publisher }})
              <v-btn
                x-small
                plain
                :ripple="false"
                class="item-recommendation-button-new-tab"
                :href="link"
                target="_blank"
              >
                <v-icon size="19"> $newTab </v-icon>
              </v-btn>
            </h3>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
    <v-divider v-if="isLast" class="item-recommendation-divider"></v-divider>
  </div>
</template>

<script>
export default {
  name: "ListItemLinkPostItem",
  props: {
    id: {
      type: Number,
      default: null,
      required: true,
    },
    date: {
      type: String,
      default: null,
      required: true,
    },
    content: {
      type: String,
      default: "",
      required: true,
    },
    link: {
      type: String,
      default: "",
      required: true,
    },
    publisher: {
      type: String,
      default: "",
      required: true,
    },
    isLast: {
      type: Boolean,
      default: false,
      required: false,
    },
  },
  methods: {
    openInNewTab() {
      window.open(this.link, "_blank");

      this.$store.dispatch("LINK_POST_CLICK_UPDATE", {
        notification_link_post_id: this.id
      });
    },
  },
};
</script>

<style scoped>
.item-recommendation {
  background-color: transparent !important;
}

.item-recommendation-content--text {
  color: #0a1b3c;
  cursor: pointer;
}

.item-recommendation-button-new-tab {
  padding-left: 0 !important;
  padding-right: 0 !important;
  min-width: 0 !important;
}

.item-recommendation-button-new-tab .v-btn__content .v-icon {
  font-size: 18px;
}

.item-recommendation-divider {
  border-color: #c4d4f2 !important;
  border-top: 1px dashed;
}
</style>
