<template>
  <v-row v-if="getLinkPosts.length > 0">
    <v-col cols="12">
      <div class="fw-600 font-16px label--text darken-2 text-center">
        {{ $t("home_recommended_topic") }}
      </div>
    </v-col>

    <v-col cols="12">
      <ListItemLinkPost
        v-for="(item, index) in getLinkPosts"
        :id="item.id"
        :date="item.post_date"
        :content="item.title"
        :link="item.url"
        :publisher="item.publisher"
        :key="index"
        :is-last="index === getLinkPosts.length - 1"
      />
    </v-col>

    <v-col cols="12" class="text-center">
      <v-btn
        class="label--text darken-1 font-15px"
        text
        tile
        small
        :ripple="false"
        to="/link-posts"
      >
        <v-icon left size="12">$play</v-icon>
        {{ $t("topic_list") }}
      </v-btn>
    </v-col>
  </v-row>
</template>

<script>
import ListItemLinkPost from "@/components/layout/link-posts/ListItemLinkPost";
import { mapGetters } from "vuex";

export default {
  name: "HomeListLinkPosts",
  components: { ListItemLinkPost },
  created() {
    this.getDataFromApi();
  },
  computed: {
    ...mapGetters(["getLinkPosts"])
  },
  data() {
    return {};
  },
  methods: {
    getDataFromApi() {
      this.$store
        .dispatch("LINK_POST_GET", {
          params: {
            paginate: 5,
            sort: "post_date",
            order: "desc",
            npr: 1
          }
        })
        .then(() => {});
    }
  }
};
</script>

<style scoped></style>
