<template>
  <div class="d-flex flex-column items-center justify-center">
    <v-row>
      <v-col class="font-weight-light font-12px">
        平均売上 {{ avgSalesAmount | formatNumber }}円
      </v-col>
      <v-col>
        <div class="d-flex flex-column align-end">
          <div class="d-flex align-center justify-start">
            <div class="thumbanil"></div>
            <div class="font-12px">売上</div>
          </div>

          <div class="d-flex align-center justify-start">
            <div class="line"></div>
            <div class="font-12px">平均</div>
          </div>
        </div>
      </v-col>
    </v-row>
    <BarChart
      :monthlySales="monthlySalesData"
      :daysInMonth="daysInMonth"
      :avgSales="avgSalesAmount"
    ></BarChart>
  </div>
</template>
<script>
import BarChart from "@/components/layout/budget/BarChart.vue";
export default {
  props: {
    monthlySalesData: {
      type: [Array],
      default: () => {
        return [];
      }
    },
    daysInMonth: {
      type: [Number, String],
      default: 0
    },
    avgSalesAmount: {
      type: [Number, String],
      default: 0
    }
  },
  components: {
    BarChart
  }
};
</script>
<style lang="scss" scoped>
.thumbanil {
  background: #93c9ff;
  width: 9px;
  height: 9px;
  border-radius: 2px;
  margin-right: 7.5px;
}
.line {
  width: 15px;
  height: 3px;
  background: #ccd7ec;
  border-radius: 2px;
  margin-right: 4.5px;
}
</style>
