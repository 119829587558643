<template>
  <v-card class="main" elevation="2">
    <div class="card-title d-flex align-center justify-start">
      <div class="card-count d-flex align-center justify-center ml-4">
        {{ count }}
      </div>
      <div
        class="header font-weight-bold main d-flex items-center justify-center"
      >
        {{ header }}
      </div>
      <div class=""></div>
    </div>
    <v-divider></v-divider>
    <div class="mt-3 ml-4 mr-4">
      <slot></slot>
    </div>
    <v-card
      v-if="showButton"
      color="transparent"
      class=" main
        card-title d-flex align-center justify-center"
    >
      <v-btn
        text
        :ripple="false"
        class="fw-700 font-14px"
        :to="footer.to ? footer.to : ''"
      >
        [ {{ footer.text ? footer.text : footer }} ]
      </v-btn>
      <!-- [ {{ footer }} ] -->
    </v-card>
  </v-card>
</template>
<script>
export default {
  props: {
    count: {
      type: [String, Object],
      require: false,
      default: ""
    },
    header: {
      type: [String, Object],
      require: false,
      default: ""
    },
    footer: {
      type: [String, Object],
      require: false,
      default: ""
    },
    showButton: {
      type: Boolean,
      require: false,
      default: true
    }
  }
};
</script>
<style scoped>
.card-count {
  width: 27px;
  height: 19px;
  background-color: #333333;
  color: white;
  font-size: 12px;
  line-height: 12px;
  position: absolute;
}
.card-title {
  height: 70px;
  position: relative;
}
.header {
  position: absolute;
}
.main {
  width: 375px;
}
</style>
