<template>
  <div class="d-flex mt-2 justify-center align-center flex-column">
    <div class="header rounded d-flex justify-start align-center">
      <v-icon class="ml-3">{{ icon }}</v-icon>
      <p class="ml-2">{{ title }}</p>
    </div>
    <SaleSummary
      v-if="arrangement === ''"
      :reverse="false"
      id="piechart1"
      :sales-data="salesData"
    ></SaleSummary>
    <SaleSummary
      v-else
      :class="arrangement"
      :reverse="true"
      id="piechart2"
      :sales-data="salesData"
    ></SaleSummary>
  </div>
</template>
<script>
import SaleSummary from "@/components/layout/budget/SaleSummary";
export default {
  props: ["title", "icon", "salesData"],
  components: {
    SaleSummary
  },
  computed: {
    arrangement() {
      return this.title === "Dinner" ? "flex-row-reverse" : "";
    }
  }
};
</script>
<style scoped>
.shiftLeft {
  transform: translateX(-6px);
}
.shiftRight {
  transform: translateX(6px);
}
.header {
  width: 100%;
  height: 46px;
  background: #6e77f0;
}
.header > p {
  font-size: 20px;
  letter-spacing: 4.5%;
  font-weight: 700;
  margin: 0;
  color: white;
}
</style>
