<template>
  <div class="d-flex flex-column items-center justify-center main-item-row">
    <v-row no-gutters class="my-4 align-center">
      <v-col class="item fw-400 name" :class="classList">{{ name }}</v-col>
      <v-col class="bill font-weight-bold">{{ price }}</v-col>
      <v-col class="description font-weight-thin ml-2">{{ description }}</v-col>
    </v-row>
    <slot></slot>
  </div>
</template>
<script>
export default {
  props: ["name", "price", "description", "classList"]
};
</script>
<style lang="scss" scoped>
.main-item-row {
  width: 100%;
  .name {
    color: #b1a354 !important;
  }
  .item {
    font-size: 14px;
    line-height: 16.41px;
    text-align: center;
  }
  .bill {
    font-size: 16px;
  }
  .description {
    font-weight: 400;
    font-size: 12px;
    text-align: center;
  }
}
</style>
